import { ROUTES } from 'src/config/routes'
import { TRichText } from 'src/utils/parseRichText'

export const PRIVACY_POLICY_DESCRIPTION_LIST_ITEMS = [
  {
    title: 'Website privacy notice',
    description: [
      {
        type: 'p',
        value:
          'This privacy notice is here to tell you what information we collect about you, what we do with that information and why we do it, who we share it with, and how we protect your privacy.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'This notice covers all personal data collected by Artificial Labs Ltd and where we tell other organisations to collect information for us. This is the same whether the information is collected by letter, email, face to face, telephone or online.',
      },
    ] as TRichText,
  },
  {
    title: 'Personal information',
    description: [
      {
        type: 'p',
        value:
          'Personal information is information that identifies a living person. This can be obvious information like name and address, or it may be something like an Internet Protocol (IP) address, browser type, etc.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'This also includes information you tell us about yourself, information we are given by other people or organisations, or what we learn by having you as a client.',
      },
      { type: 'br' },
      {
        type: 'p',
        value: 'We must take extra care when collecting and using these types of information.',
      },
      { type: 'br' },
      { type: 'strong', value: 'Why we need your personal information' },
      { type: 'br' },
      { type: 'br' },
      {
        type: 'p',
        value:
          'We use personal information in many ways so that we can deliver our services. We use personal information:',
      },
      { type: 'br' },
      {
        type: 'list',
        value: [
          'to provide services and support to you;',
          'manage our services to see how we can best deliver services and support to you;',
          'to monitor how well we dealt with your request, for admin purposes and to handle any complaints;',
          'so that we can tell you about and provide services that suit your needs;',
          'where we must process personal information so that we comply with legal obligations for the prevention and detection of crime e.g., investigating fraud;',
          'so that we can make financial transactions for payment;',
          'for statistical and research purposes.',
        ],
      },
      { type: 'br' },
      {
        type: 'strong',
        value: 'Why we can use your personal information',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          "We can only collect and use (process) personal information if we have a proper reason or 'legal basis' to do so. In most cases, it is because you have asked us to deliver a service. We call this a statutory duty.",
      },
      { type: 'br' },
      {
        type: 'strong',
        value: 'Who we share information with',
      },
      { type: 'br' },
      { type: 'br' },
      {
        type: 'p',
        value:
          'We do not sell your personal information to anyone. We do not share your personal information with any people or organisations unless they are providing services to us under contract or where providing that information is allowed by, or required by, law.',
      },
    ] as TRichText,
  },
  {
    title: 'Third-party service providers',
    description: [
      {
        type: 'p',
        value:
          'We may share your information with third parties that perform services for us or on our behalf, including data analysis, email delivery, hosting services, customer service, and marketing assistance.',
      },
      { type: 'br' },
      {
        type: 'p',
        value: 'Here is a list of the third-party service providers we use:',
      },
      { type: 'br' },
      {
        type: 'list',
        value: [
          'Amazon Web Services for hosting services',
          [
            "Google Analytics for data analytics (for more information, visit 'How Google uses data when you use our partners' sites or apps': <a href='https://policies.google.com/technologies/partner-sites' target='_blank'>https://policies.google.com/technologies/partner-sites</a>)",
          ],
          'Google Tag Manager for data analytics',
          'Hubspot Analytics for data analytics',
          'Hubspot CRM for customer service',
          'LinkedIn Website Retargeting for marketing assistance',
          'Mailchimp for email delivery and marketing assistance',
        ],
      },
    ] as TRichText,
  },
  {
    title: 'Reviewing and improving our services',
    description: [
      {
        type: 'p',
        value:
          'We regularly use personal information to look at how people are using our services and whether we are performing well. This may be assessments that we do within our services, or we might contact you to ask you for feedback on our services.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'This helps us to improve the way we deliver services to you, and to plan the best way to deliver services.',
      },
    ] as TRichText,
  },
  {
    title: 'Anti-fraud and crime prevention',
    description: [
      {
        type: 'p',
        value:
          'We may need to use personal information across all our services to help to prevent and detect crime and fraud. The law allows us to use and share personal information so that this can be done. We may be required to share information with organisations like the police or professional bodies like those that regulate a particular type of job, such as solicitors.',
      },
    ] as TRichText,
  },
  {
    title: 'Information requests',
    description: [
      {
        type: 'p',
        value:
          'Artificial Labs Limited must respond to requests made under laws that allow access to information. The Freedom of Information Act 2000 and the Environmental Information Regulations 2004 require us to answer requests for information that we hold. Data protection legislation also requires us to respond to requests for information about individuals, which we go into detail in the Your Rights section of this notice.',
      },
      { type: 'br' },
      {
        type: 'p',
        value: 'All Artificial Labs Limited services use personal data so that we can comply with these laws.',
      },
    ] as TRichText,
  },
  {
    title: 'Complaints',
    description: [
      {
        type: 'p',
        value: 'We use personal information to handle any complaints you or another person makes to us.',
      },
    ] as TRichText,
  },
  {
    title: 'Insight and profiling',
    description: [
      {
        type: 'p',
        value:
          'We use personal information to analyse patterns and trends of service use. We call this insight, and we use the information to plan and improve the services we deliver and to help us make decisions.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'By bringing together and analysing customer information which the company holds (creating insight) it helps us to deliver high quality services where they are most needed.',
      },
    ] as TRichText,
  },
  {
    title: 'Your rights',
    description: [
      {
        type: 'p',
        value:
          'Individuals like you (data subjects) have rights under data protection laws to control what information you provide to us and what we can do with it.',
      },
      { type: 'br' },
      {
        type: 'p',
        value: 'Asking for your own information is called a Subject Access Request (SAR).',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'More details on your full rights under the data protection law can be found on the ICO website <a href="https://www.ico.org.uk" target="_blank">www.ico.org.uk</a>',
      },
    ] as TRichText,
  },
  {
    title: 'You can ask for access to the information we hold on you',
    description: [
      {
        type: 'p',
        value:
          'You also have the right to ask for information we hold about you and the services you receive from us. When we receive a request from you in writing (Subject Access Request), we must give you access to everything we’ve recorded about you.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'The law tells us that we can withhold information from you where it also contains confidential information about other people or may stop us from preventing or detecting crime.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'Requests are free and we must respond within 30 days, unless your request is large or complicated, when we are allowed to take longer to respond to you.',
      },
    ] as TRichText,
  },
  {
    title: 'You can ask to change information you think is inaccurate',
    description: [
      {
        type: 'p',
        value:
          'If you believe the information we hold about you is wrong, you can ask us to change it. This might be if we have spelt your name wrong or have an incorrect address.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'You should speak to the contact person who is delivering the service to you to let them know you think something is wrong.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'We may not always be able to change or remove that information but we’ll correct factual inaccuracies and may include your comments in the record to show that you disagree with it.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'Where we have shared your personal information with others, we’ll do what we can to tell them to correct the information.',
      },
    ] as TRichText,
  },
  {
    title: 'You can ask us to delete information (right to be forgotten)',
    description: [
      {
        type: 'p',
        value: 'In some circumstances you can ask for your personal information to be deleted, for example:',
      },
      { type: 'br' },
      {
        type: 'list',
        value: [
          'where your personal information is no longer needed for the reason it was collected in the first place',
          'where you have removed your consent for us to use your information (where there is no other legal reason for us to use it)',
          'where there is no legal reason for the use of your information',
          'where deleting the information is a legal requirement',
        ],
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'We cannot always delete your information, for example, if there is a legal reason we need to keep it or where you still expect us to deliver a service to you. But we should tell you why we can’t delete what you are asking us to. Where we have shared your personal information with others, we’ll do what we can to make sure those using your personal information comply with your request for erasure.',
      },
    ] as TRichText,
  },
  {
    title: 'Data Controller: Artificial Labs Limited',
    description: [
      {
        type: 'p',
        value:
          'Artificial Labs Limited is required to appoint a Data Protection Officer (DPO), who is responsible for monitoring our compliance with data protection legislation and advising on our data protection obligations.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'If you have any questions or worries about how the company collects or uses your personal information you can contact the Data Protection Officer:',
      },
      { type: 'br' },
      {
        type: 'p',
        value: '<a href="mailto:johnny@artificial.io">johnny@artificial.io</a>',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'For independent advice about data protection and privacy, you can contact the Information Commissioner’s Office (ICO). The ICO oversees how organisations comply with data protection legislation in the UK:',
      },
      { type: 'br' },
      {
        type: 'p',
        value: "Information Commissioner's Office",
      },
      { type: 'p', value: 'Wycliffe House' },
      { type: 'p', value: 'Water Lane' },
      { type: 'p', value: 'Wilmslow' },
      { type: 'p', value: 'Cheshire' },
      { type: 'p', value: 'SK9 5AF' },
      {
        type: 'p',
        value: 'Tel: 0303 123 1113 (local rate) or 01625 545 745 if you prefer to use a national rate number.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'Alternatively, visit <a href="https://www.ico.org.uk" target="_blank">www.ico.org.uk</a> or email <a href="mailto:casework@ico.org.uk">casework@ico.org.uk</a>',
      },
    ] as TRichText,
  },
  {
    title: 'Visitors to our websites',
    description: [
      {
        type: 'p',
        value:
          'When someone visits our website we collect routine internet log information, which allows us to see visitor behaviour patterns. We do this to determine which pages are being viewed as this assists us in improving our website.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'Internet log information is collected in a way which doesn’t allow us to identify you and we do not make any attempt to find out the identities of individuals visiting our site.',
      },
    ] as TRichText,
  },
  {
    title: 'Cookies',
    description: [
      {
        type: 'p',
        value:
          'Cookies are small text files, placed on your computer or mobile device by the websites you visit. They are widely used to make websites work, or work more efficiently.',
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'We use cookies to control our website’s functions, to keep statistics and for session management. We do not store any personal information in cookies. Examples of how we use cookies include:',
      },
      {
        type: 'list',
        value: [
          "Recognising your computer so you don't have to give the same information several times.",
          'Measuring how many people are using our website, so we can make improvements.',
        ],
      },
      { type: 'br' },
      {
        type: 'p',
        value:
          'If you do not want to accept cookies, your browser can be set to automatically reject them or to inform you every time a website asks to store a cookie. You can also delete previously stored cookies.',
      },
      { type: 'br' },
      {
        type: 'p',
        value: 'Our cookie policy is set to be compliant with the EU Privacy and Electronic Communications Directive.',
      },
    ] as TRichText,
  },
  {
    title: 'Computer viruses',
    description: [
      {
        type: 'p',
        value:
          'We make every effort to ensure all information is virus checked before it is uploaded and made available on our website. Artificial Labs Limited cannot accept responsibility for any loss or damage which may occur from use of downloaded documents, it is the responsibility of users to re-check all downloaded documents with their own virus check software.',
      },
    ] as TRichText,
  },
  {
    title: 'External links',
    description: [
      {
        type: 'p',
        value:
          'This website contains external links to third party sites. Our privacy policy applies only to information collected by Artificial Labs Limited. When you are transferring to another site you should read their privacy statement on the use of your information before submitting any personal details.',
      },
    ] as TRichText,
  },
  {
    title: 'Security',
    description: [
      {
        type: 'p',
        value: `The security of your personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. Whilst we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. Visit our <a href='${ROUTES.security()}'>Security</a> page for more information.`,
      },
    ] as TRichText,
  },
  {
    title: 'Updating this privacy notice',
    description: [
      {
        type: 'p',
        value:
          'We will update this privacy notice when how we collect and use your information changes. We encourage you to check this privacy notice before providing us with any personal information. We reserve the right to update or change our privacy notice at any time. Your continued use of the service after we post any modifications to the privacy notice on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified privacy notice.',
      },
    ] as TRichText,
  },
]
