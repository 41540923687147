import * as s from './ArTextHero.module.scss'

import React from 'react'
import { TArTextHeroProps } from './ArTextHero.types'
import classNames from 'classnames'
import { parseRichText } from 'src/utils/parseRichText'

export const ArTextHero: React.FC<TArTextHeroProps> = ({ className, style, title, paragraph, children }) => {
  const isParagraphRichText = typeof paragraph !== 'string'
  return (
    <div className={classNames(s.container, className)} style={style}>
      <div>
        <h2>{title}</h2>
        {paragraph &&
          (isParagraphRichText ? (
            <p dangerouslySetInnerHTML={{ __html: parseRichText(paragraph) }} />
          ) : (
            <p>{paragraph}</p>
          ))}
      </div>
      <div>{children}</div>
    </div>
  )
}
