import * as s from './ArDescriptionList.module.scss'

import React from 'react'
import { TArDescriptionListProps } from './ArDescriptionList.types'
import { parseRichText } from 'src/utils/parseRichText'

export const ArDescriptionList: React.FC<TArDescriptionListProps> = ({ data }) => {
  return (
    <div className={s.container}>
      {data.map((item, i) => (
        <React.Fragment key={i}>
          <h4>{item.title}</h4>
          <div>
            <div
              className={s.description}
              dangerouslySetInnerHTML={{
                __html:
                  typeof item.description === 'string'
                    ? '<p>' + item.description + '</p>'
                    : parseRichText(item.description),
              }}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
