import { ArContainer } from 'src/components/ArContainer'
import { ArDescriptionList } from 'src/components/ArDescriptionList'
import { ArSeo } from 'src/components/ArSeo'
import { ArTextHero } from 'src/components/ArTextHero'
import { PRIVACY_POLICY_DESCRIPTION_LIST_ITEMS } from 'src/data/privacyPolicyDescriptionList'
import { ROUTES } from 'src/config/routes'
import React from 'react'

const PrivacyPolicyPage = () => {
  return (
    <>
      <ArSeo route={ROUTES.privacyPolicy()} />
      <ArContainer>
        <ArTextHero title="Privacy policy" />
        <ArDescriptionList data={PRIVACY_POLICY_DESCRIPTION_LIST_ITEMS} />
      </ArContainer>
    </>
  )
}

export default PrivacyPolicyPage
